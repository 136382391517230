import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../config/https";
import Sidebar from "../../layouts/Blogsidebar";

const Content = () => {
  const [blogblock, setBlogblock] = useState([]);
  const [loader, setLoader] = useState(false);
  const getBlog = () => {
    setLoader(true);
    axiosInstance
      .get(`blog`)
      .then((response) => {
        const Data = response.data.data;
        console.log(Data);
        if (response.status === 200) {
          setBlogblock(Data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => setLoader(false));
  };
  useEffect(() => {
    getBlog();
  }, []);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <section className="blog-section section-gap-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {/* Blog loop(Grid) */}
              <div className="blog-loop grid-blog row justify-content-center">
                {/* Single Post */}
                <div className="row">
                  {blogblock.map((item, i) => {
                    const removeHTMLTags = (html) => {
                      const doc = new DOMParser().parseFromString(
                        html,
                        "text/html"
                      );
                      return doc.body.textContent || "";
                    };

                    const truncatedContent = removeHTMLTags(
                      item.blog
                    ).substring(0, 20);
                    console.log(truncatedContent);

                    return (
                      <>
                        <div
                          key={i}
                          className="col-lg-6 col-md-6 col-10 col-tiny-12"
                        >
                          <div className="single-post-box">
                            <div className="post-thumb">
                              <img
                                src={item.image}
                                alt=""
                                width={370}
                                height={270}
                              />
                            </div>
                            <div className="post-content">
                              <span className="post-date">
                                <i className="far fa-calendar-alt" />
                                {item.date}
                              </span>
                              <h3 className="title">
                                <Link to="/blogdetail">
                                  {item.title.substring(0, 20) +
                                    (item.title.length > 20 ? "..." : "")}
                                </Link>
                              </h3>
                              <p>{item.text}</p>
                              <Link
                                to={`/blogdetail/${item.id}`}
                                className="post-link"
                              >
                                {truncatedContent.substring(0, 20) +
                                  (truncatedContent.length > 20 ? "..." : "")}

                                <i className="far fa-long-arrow-right" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              {/* Pagination */}
            </div>
            <div className="col-lg-4 col-md-8">
              {/* sidebar */}
              <Sidebar blogdata={blogblock} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
