import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Logo from "../../img/Logo.png";
import Navmenu from "./Navmenu";
import OffCanvas from "./Offcanvas";

const Headertwo = (props) => {
  const [matches, setMatches] = useState(false);
  const [stickyheader, setStickyheader] = useState(false);
  const isLogin = localStorage.getItem("token");
  const userDetails = JSON.parse(localStorage.getItem("User"));
  const [togglemethod, setTogglemethod] = useState(false);
  useEffect(() => {
    // componentDidMount
    setMatches(window.matchMedia("(max-width: 991px)").matches);
    setStickyheader(window.pageYOffset > 100);

    const handleResize = () => {
      setMatches(window.matchMedia("(max-width: 991px)").matches);
    };

    const handleScroll = () => {
      setStickyheader(window.pageYOffset > 100);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    // componentWillUnmount
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const LogOut = () => {
    localStorage.removeItem("token");
    Swal.fire({
      icon: "success",
      title: "LogOut SuccessFully",
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  };

  const mobileactive = matches ? "breakpoint-on" : "";
  const stickyheaderClass = stickyheader ? "sticky-on" : "";

  return (
    <Fragment>
      <header className={`header-two sticky-header ${stickyheaderClass}`}>
        {/* Header Menu */}
        <div className={`header-nav sticky-nav ${props.className}`}>
          <div className="container-fluid container-1600">
            <div className={`nav-container ${mobileactive}`}>
              {/* Site Logo */}
              <div className="site-logo">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ width: 240, height: 40 }}
                  />
                </Link>
              </div>
              {/* Main Menu */}
              <div  className={togglemethod ? 'nav-menu d-lg-flex align-items-center menu-on' : 'nav-menu d-lg-flex align-items-center'}>
                {/* Navbar Close Icon */}
                <div className="navbar-close" onClick={()=>setTogglemethod(false)}>
                  <div className="cross-wrap">
                    <span />
                    <span />
                  </div>
                </div>
                {/* Mneu Items */}
                <div className="menu-items">
                  <Navmenu />
                </div>
                {/* Pushed Item */}
                <div className="nav-pushed-item" />
              </div>
              {/* Navbar Extra */}
              <div className="navbar-extra d-flex align-items-center">
                {/* language selection */}
                <div className="language-selection">
                  {isLogin ? (
                    <>
                      <Link className="language-btn text-capitalize" to="#">
                        {userDetails?.name}
                        <i className="fa fa-user" />
                      </Link>
                      <ul className="language-list">
                        <li>
                          <Link to="/datatable">
                            Dashb... <i className="fa fa-tachometer" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/login" onClick={LogOut}>
                            Log Out <i className="fa fa-sign-out" />
                          </Link>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <Link className="language-btn" to="/login">
                        Login
                        <i className="far fa-user" />
                      </Link>
                    </>
                  )}
                </div>
                {/* Navbar Toggler */}
                <div className="navbar-toggler" onClick={()=>setTogglemethod(true)}>
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="offcanvas-wrapper">
        <div className="offcanvas-overly" />
        <div className="offcanvas-widget">
          <Link to="#" className="offcanvas-close">
            <i className="fal fa-times" />
          </Link>
          <OffCanvas />
        </div>
      </div>
    </Fragment>
  );
};

export default Headertwo;
