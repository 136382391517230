import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { axiosInstance } from "../../config/https";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import Headertwo from "../layouts/Headertwo";
import BannerImage from '../../img/Datareport.jpeg'
const Data_Reports = () => {
  const [reports, setReports] = useState([]);
  const [loader, setLoader] = useState(false);
  const getReports = () => {
    setLoader(true);
    axiosInstance
      .get(`dataReport`)
      .then((res) => {
        const Data = res.data.data;
        console.log(Data, res);
        if (res.status === 200) {
          setReports(Data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => setLoader(false));
  };
  useEffect(() => {
    getReports();
  }, []);

  return (
    <Fragment>
      {loader ? <div className="loader"></div> : null}
      <MetaTags>
        <title>Ethio Finance</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Headertwo />
      <Breadcrumbs breadcrumb={{ pagename: "Financial Analysis Reports" }} BannerImage={BannerImage} />
      <section className="job-list-section section-gap grey-bg">
        <div className="container">
          <div className="job-lists">
            {reports.map((item, i) => (
              <div key={i} className="single-job">
                <h4 className="title">
                  {item.name} <span className="job-time">({"Admin"})</span>
                </h4>
                <p>{item.subject}</p>
                <a
                  href={item.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="apply-btn"
                >
                  {`View Data & Reports `}{" "}
                  <i className="far fa-long-arrow-right" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>{" "}
      <Footer />
    </Fragment>
  );
};

export default Data_Reports;
