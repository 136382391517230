import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import Headertwo from "../layouts/Headertwo";
import Content from "../sections/blogdetail/Content";

class Blogdetail extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Ethio Finance | Blog Detail</title>
          <meta name="description" content="#" />
        </MetaTags>
        <Headertwo />
        <Breadcrumbs breadcrumb={{ pagename: "Blog Detail" }} />
        <Content />

        <Footer />
      </Fragment>
    );
  }
}

export default Blogdetail;
