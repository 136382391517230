import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { Document, Image, PDFViewer, Page } from "@react-pdf/renderer";
import { toPng } from "html-to-image";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
const Linechart = ({ getChart }) => {
  console.log(getChart, "getChart");
  const categoriesSet = new Set();
  const newgetChart = getChart.map((e) => e.record);
  const keys = Object.keys(newgetChart?.[0]?.[0] || {});
  getChart.forEach((e) => {
    e.record.forEach((record) => {
      categoriesSet.add(record.year.toString());
    });
  });

  const categories = Array.from(categoriesSet);

  // const seriesData = [
  //   {
  //     name: "High - 2013",
  //     data: [28, 29, 33, 36, 32, 32, 33],
  //   },
  //   {
  //     name: "Low - 2013",
  //     data: [12, 11, 14, 18, 17, 13, 13],
  //   },
  // ];
  const highestValues = getChart.map((e) => ({
    data: e?.record?.map((record) => record?.sum),
  }));

  const maxSum = Math.max(...highestValues.flatMap((e) => e.data));
  console.log(maxSum, "highestValues");
  const seriesData = getChart.map((e) => ({
    name: e?.label,
    data: e?.record?.map((record) => record?.sum.toFixed(2)),
  }));
  console.log(seriesData, "seriesData");

  const colors = getChart.map(() => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
  });
  const options = {
    chart: {
      height: 1300,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: true,
        tools: {
          download: true, // Enable the download button
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: "chart_data.csv", // Specify the CSV filename
          },
          svg: {
            filename: "chart_image",
          },
          png: {
            filename: "chart_image",
          },
        },
      },
    },
    // colors: ["#77B6EA", "#545454"],
    colors: colors,
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Line Chart",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: categories,
      title: {
        text: "Year",
      },
    },
    // yaxis: {
    //   title: {
    //     text: "",
    //   },
    //   min: 0.5,
    //   max: Math.round(maxSum),
    // },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (val) {
          console.log(val, "New Val")
          return val.toFixed(2);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };
  const ref = useRef(null);

  useEffect(() => {
    ApexCharts.exec("chart", "updateOptions", options);
  }, [options]);

  const [convertedImg, setConvertedImg] = useState("");
  const pdfContainerRef = null;
  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        console.log(dataUrl);
        setConvertedImg(dataUrl);
        // const link = document.createElement("a");
        // link.download = "my-image-name.png";
        // link.href = dataUrl;
        // link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);
  const [show, setShow] = useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);

  return (
    <>
      <div>
        <div className="row justify-content-end">
          <button
            onClick={() => {
              onButtonClick();
              onShow();
            }}
            className="btn  btn-outline-primary float-right my-4 mx-4"
          >
            Print <i className="fa fa-print" />
          </button>
        </div>
        <div ref={ref} id="html-element">
          <h4 className="text-capitalize text-center my-4">
            {keys
              .filter(
                (s) =>
                  s !== "bank_id" &&
                  s !== "bank" &&
                  s !== "id" &&
                  s !== "date" &&
                  s !== "branch" &&
                  s !== "establish_year" &&
                  s !== "year" &&
                  s !== "count" &&
                  s !== "sum"
              )
              .map((e, index, array) => {
                e = e.replace(/_/g, " ");

                if (index === array.length - 1) {
                  return e;
                } else if (index === array.length - 2) {
                  return e + " & ";
                } else {
                  return e + ", ";
                }
              })}
          </h4>
          <div id="chart">
            <Chart options={options} series={seriesData} type="line" height={350} />
          </div>
        </div>
        <Modal show={show} onHide={() => setShow(false)} size="xl">
          <ModalBody>
            <PDFViewer style={{ width: `100%`, height: "100vh" }}>
              <Document>
                <Page size="A4">
                  <div ref={pdfContainerRef}>
                    {convertedImg && (
                      <Image src={`${convertedImg}`} alt="no Img" />
                    )}
                    <br />
                    <br />
                    <br />
                  </div>
                </Page>
              </Document>
            </PDFViewer>
          </ModalBody>
          <ModalFooter>
            <div className="btn btn-outline-danger" onClick={onHide}>
              Close
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default Linechart;
