import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import Breadcrumbs from '../layouts/Breadcrumbs';
import Footer from '../layouts/Footer';
import Headertwo from '../layouts/Headertwo';
import Content from '../sections/contact/Content';
import BannerImage from '../../img/Datareport.jpeg'

class Contact extends Component {
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>EthioFinance | Contact Us</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Headertwo />
                <Breadcrumbs breadcrumb={{ pagename: 'Contact Us' }} BannerImage={BannerImage} />
                <Content />
                
                <section className="client-section">
                    <div className="container">
                        <div className="client-slider section-gap line-top">
                            {/* <Client /> */}
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}

export default Contact;