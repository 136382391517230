import React from "react";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import cta from "../../img/dollar.jpg";
import { axiosInstance } from "../../config/https";

// const categories = [
//   { title: "Financial Planning" },
//   { title: "Relationship Buildup" },
//   { title: "Investement Planning" },
//   { title: "Marketing Strategy" },
//   { title: "Product Strategy" },
// ];

// const tags = [
//   { tag: "Business" },
//   { tag: "Corporate" },
//   { tag: "HTML" },
//   { tag: "Finance" },
//   { tag: "Investment" },
//   { tag: "CSS" },
//   { tag: "Planing" },
//   { tag: "Creative" },
// ];

const Blogsidebar = ({ blogdata }) => {
  const [blogblock, setBlogblock] = useState([]);
  const [loader, setLoader] = useState(false);
  const getBlog = () => {
    setLoader(true);
    axiosInstance
      .get(`blog`)
      .then((response) => {
        const Data = response.data.data;
        console.log(Data);
        if (response.status === 200) {
          setBlogblock(Data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => setLoader(false));
  };
  useEffect(() => {
    getBlog();
  }, []);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim() === "") {
      setFilteredData([]);
    } else {
      const filteredResults = blogblock.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filteredResults);
    }
  };

  const displayData = searchQuery.trim() === "" ? blogblock : filteredData;
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="sidebar">
        {/* Search Widget */}
        <div>
          <div className="widget search-widget">
            <form>
              <input
                type="search"
                placeholder="Search here"
                value={searchQuery}
                onChange={handleSearch}
              />
              <button type="submit">
                <i className="far fa-search" />
              </button>
            </form>
          </div>

          <div className="widget recent-post-widget">
            <h4 className="widget-title">Recent News</h4>
            <div className="post-loops">
              {displayData.length === 0 ? (
                <p>No data found.</p>
              ) : (
                displayData.slice(0, 4).map((item, i) => (
                  <div key={i} className="single-post">
                    <div className="post-thumb">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="post-desc">
                      <span className="date">
                        <i className="far fa-calendar-alt" />
                        {item.date}
                      </span>
                      <Link to={`/blogdetail/${item.id}`}>
                        {item.title.substring(0, 20) +
                          (item.title.length > 20 ? "..." : "")}
                      </Link>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <div
          className="widget cta-widget"
          style={{
            backgroundImage: "url(" + cta + ")",
            backgroundSize: "370px 455px",
          }}
        >
          <h4 className="title">Need Any Help for Business & Consulting</h4>
          <p style={{ color: "white" }}>
            But I Must Explain to you how all this mistaken idea of denouncing
            pleasure and prasing pain was
          </p>
          {/* <p>
            <a
              href="tel:+0123456789"
              style={{ color: "white", fontSize: "26px" }}
            >
              <i className="far fa-phone" style={{ color: "orange" }} />
              +012 (345) 6789
            </a>
          </p>
          <p>
            <a
              href="mailto:support@gmail.com"
              style={{ color: "white", fontSize: "18px" }}
            >
              <i className="far fa-envelope-open" />
              support@gmail.com
            </a>
          </p> */}
          <ul className="contact-infos">
            <li>
              <Link to="tel:+0123456789"  style={{ color: "white", fontSize: "22px" }}>
                <i className="far fa-phone" style={{color:'#f39a01'}}  /> &nbsp;&nbsp;&nbsp;
                +012 (345) 6789
              </Link>
            </li>
            <li>
              <Link to="mailto:support@gmail.com"  style={{ color: "white", fontSize: "18px" }}>
                <i className="far fa-envelope-open" style={{color:'#f39a01'}} /> &nbsp;&nbsp;
                Support@gmail.com
              </Link>
            </li>
            <li  style={{ color: "white", fontSize: "18px" }}>
           
              <i className="far fa-map-marker-alt" style={{color:'#f39a01'}}  /> &nbsp; &nbsp; Broklyn Street USA
            </li>
          </ul>
          <Link to="/contact" className="main-btn">
            Send Message
          </Link>
        </div>
      </div>
    </>
  );
};

export default Blogsidebar;
