import React, { Component, Fragment } from 'react'
import About from './About'
import Banner from './Banner'

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Banner />
                <About />
                {/* <Service /> */}
                {/* <Video /> */}
                {/* <Features /> */}
                {/* <Whyus /> */}
                {/* <Counter /> */}
                {/* <Team /> */}
                {/* <Skills /> */}
                {/* <Testimonials /> */}
                {/* <section className="client-section">
                    <div className="container">
                        <div className="client-slider section-gap line-bottom">
                            <Client />
                        </div>
                    </div>
                </section> */}
                {/* <Blog /> */}
            </Fragment >
        );
    }
}

export default Content;