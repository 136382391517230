import React from "react";
import { Link } from "react-router-dom";

import illustration from "../../../img/pasie.jpg";
function About(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">About Us</span>
                {/* <h2 className="title">
                  Professional Business <br /> Guidance Agency{" "}
                </h2> */}
              </div>
              <p className="mb-25">
                Founded in 2019 by Tsegaye Yilma Dessalegn, Ethio Financial
                Reports works to become the source of data and reports on
                Ethiopias private Banking. We help Bank executives, bank
                shareholders, bank regulators and other interested parties
                access quick and comprehensive data on private bank performance
                in Ethiopia.
              </p>
              <ul className="about-list">
                <li>
                  {" "}
                  <i className="far fa-check" /> We partner with Industry
                  Experts and Researchers to Add Value to the Data.
                </li>
              </ul>
              <Link to="/contact" className="main-btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;
