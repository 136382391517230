import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";
import Headertwo from "../layouts/Headertwo";
import Content from "../sections/bloggrid/Content";
import BannerImage from '../../img/Datareport.jpeg'
class Bloggrid extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>EthioFinances | Financal News</title>
          <meta name="description" content="#" />
        </MetaTags>
        <Headertwo />
        <Breadcrumbs breadcrumb={{ pagename: "Financal News" }} BannerImage={BannerImage}  />
        <Content />

        <Footer />
      </Fragment>
    );
  }
}

export default Bloggrid;
