import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import illustration from '../../../img/img2.jpg';
import { axiosInstance } from '../../../config/https';
import Swal from 'sweetalert2';

const Content = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [loader, setLoader] = useState(false)
  const handleSumbit = (e) => {
    setLoader(true)
    e.preventDefault()
    const formdata = new FormData()
    formdata.append("email", email)
    formdata.append("fullname", name)
    formdata.append("phone", phone)
    formdata.append("comment", message)
    axiosInstance.post(`contact`, formdata).then((e) => {
      const Data = e?.data
      console.log(Data)
      if (Data?.status === true) {
        window.scrollTo({top:0,behavior:"smooth"})
        setLoader(false)

        Swal.fire({
          icon: "success",
          title: "We will contact you soon..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        document.getElementById('form').reset()
      } else {
        setLoader(false)
      }
    }).catch((e) => {
      setLoader(false)

      Swal.fire({
        icon: "error",
        title: "Something Went wrong !",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    })
  }
  const handleChange = (setState) => (e) => setState(e.target.value)
  return (
    <section className="contact-section contact-page section-gap-top">
      <div className="container">
        <div className="contact-info">
          <div className="row justify-content-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="illustration-img text-center">
                <img src={illustration} alt="" width={453} height={570} />
              </div>
            </div>
            <div className="col-lg-6 col-md-10 order-1 order-lg-2">
              <div className="contact-info-content">
                <div className="section-title left-border mb-40">
                  <span className="title-tag">Get In Touch</span>
                  <h2 className="title">Need Any Help For Business &amp; Consulting</h2>
                </div>
                <p>
                  But I must explain to you how all this mistaken idea of denouncing pleasure and praising
                  pain was
                </p>
                <ul>
                  <li className="phone">
                    <Link to="tel:+0123456789">
                      <i className="far fa-phone" />+012 (345) 6789
                    </Link>
                  </li>
                  <li>
                    <i className="far fa-envelope-open" />
                    <Link to="#">support@gmail.com</Link>
                  </li>
                  <li>
                    <i className="far fa-map-marker-alt" />Broklyn Street USA
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form grey-bg">
          <div className="row no-gutters justify-content-center">
            <div className="col-10">
              <div className="section-title text-center mb-40">
                <h2 className="title">Don’t Hesited To Contact Us</h2>
              </div>
              <form onSubmit={handleSumbit} id='form'>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input type="text" placeholder="Your Full Name" onChange={handleChange(setName)} />
                      <span className="icon">
                        <i className="far fa-user-circle" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input type="email" placeholder="Your Email Address" onChange={handleChange(setEmail)} />
                      <span className="icon">
                        <i className="far fa-envelope-open" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-group mb-30">
                      <input type="tel" placeholder="Your Phone" onChange={handleChange(setPhone)} />
                      <span className="icon">
                        <i className="far fa-phone" />
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group textarea mb-30">
                      <textarea placeholder="Write Message" defaultValue={''} onChange={handleChange(setMessage)} />
                      <span className="icon">
                        <i className="far fa-pencil" />
                      </span>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button type="submit" className="main-btn">
                      Send Message {loader?<i className='fa fa-spinner fa-spin fs-2' />:null}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid container-1600">
        <div className="contact-map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d91364.00338430358!2d-73.9756152824982!3d40.698190064837625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1598380219280!5m2!1sen!2sbd"
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden="false"
            tabIndex={0}
          />
        </div>
      </div>
    </section>
  );
};

export default Content;
