import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import { useHistory } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosInstance } from "../../config/https";
import Logo from "../../img/Logo.png";
const Login = () => {
  const history = useHistory();
  const [showPassword, setShowpassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const isLogin = () => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);
    axiosInstance
      .post("login", formdata)
      .then((e) => {
        const Data = e.data.data;
        console.log(Data);
        if (Data?.status === true) {
          setLoader(false);
          localStorage.setItem("User", JSON.stringify(Data.user));
          localStorage.setItem("token", Data?.token);
          Swal.fire({
            icon: "success",
            title: Data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          history.push("/Datatable");
        } else {
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div style={{ backgroundColor: "rgb(218 69 27)", height: "100vh" }}>
        <div className="container ">
          <div className="row">
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-md-3" />
                <div className="col-md-6">
                  <br />
                  <br />
                  <div
                    className="card shadow-lg  rounded my-5 p-3"
                    style={{ backgroundColor: "#3c383830" }}
                  >
                    <div className="container">
                      <div className="text-center">
                        <Link to={"/"}>
                          <img
                            src={Logo}
                            alt=""
                            className="w-75 rounded my-3"
                          />
                        </Link>
                      </div>
                      <div className="my-3">
                        <div className="form-group">
                          <label>
                            <h6 className="text-white">Email</h6>
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="abc@gmail.com"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            <h6 className="text-white">Password</h6>
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <button
                              className={`btn  ${
                                showPassword ? "btn-success" : "btn-danger"
                              }`}
                              type="button"
                              onClick={() => setShowpassword(!showPassword)}
                            >
                              {showPassword ? (
                                <AiOutlineEyeInvisible className=" fs-4" />
                              ) : (
                                <AiOutlineEye className=" fs-4" />
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="text-center mt-3">
                          <p className="text-white ">
                            You don't have account?{" "}
                            <Link to={"/signup"} className="text-primary">
                              SignUp
                            </Link>
                          </p>

                          <button
                            className="main-btn main-btn-3 w-50 "
                            onClick={isLogin}
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
