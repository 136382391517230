import React from "react";
import { Link } from "react-router-dom";

import illustration from "../../../img/pasie.jpg";
function About(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img src={illustration} alt="" width={654} height={556} />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">About Us</span>
                <h2 className="title">
                  Seamless Access to Bank Financial Data
                </h2>
              </div>
              <p className="mb-25">
                We believe that accessing bank financial data should be a
                seamless and efficient process. With our user-friendly platform,
                decision makers can effortlessly browse through a wealth of
                information, gaining access to a wide range of key financial
                metrics, performance indicators, and trends in the Ethiopian
                banking sector. Our goal is to empower our users to make
                data-driven decisions that drive growth and success.
              </p>
              <ul className="about-list">
                <li>
                  {" "}
                  <i className="far fa-check" /> We partner with Industry
                  Experts and Researchers to Add Value to the Data.
                </li>
              </ul>
              <Link to="/about" className="main-btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
