/* eslint-disable react-hooks/exhaustive-deps */
import { Document, Image, PDFViewer, Page } from "@react-pdf/renderer";
import { toPng } from "html-to-image";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";

// equity_in_mill loan_mill interest_income_mill
// let notinpercent = [""]
// const notInPercent = {
//   equity_in_mill: true,
//   loan_mill: true,
//   interest_income_mill: true
// }
const VerticalBarChart = ({ getChart,selectedOptionss }) => {
  const getBarData = (barData, reqkey,settrue) => {
    console.log(barData, reqkey, "check");
    const newData = barData
      .flat(1)
      .map((key, index) =>
        key.record
          .flat(1)
          .map((record) => ({
            x: key.label,
            y: record[reqkey] ,
            year: record.year,
          }))
      )
      .flat();
    const years = Array.from(new Set(newData.map((item) => item.year)));
    const mappedData = years.map((year) => ({
      name: year,
      data: newData
        .filter((item) => item.year === year)
        .map((item) => ({ x: item.x , y: item.y })),
    }));
  
    return mappedData;
  };

  const newgetChart = getChart.map((e) => e.record);
  const keys = Object.keys(newgetChart?.[0]?.[0] || {});
  const ref = useRef(null);
  const [trus,settrue] = useState(null)
  console.log(trus,'trus')
  const [barData, setBarData] = useState(getBarData(getChart, keys[0],settrue));

  const [convertedImg, setConvertedImg] = useState("");
  const pdfContainerRef = null;
  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        console.log(dataUrl);
        setConvertedImg(dataUrl);
        // const link = document.createElement("a");
        // link.download = "my-image-name.png";
        // link.href = dataUrl;
        // link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);
  const [show, setShow] = useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
// const CheckPercent = selectedOptionss.inc((e)=> e.value === "asset_utilization"   )
// const filteredProducts = selectedOptionss.filter((product) => {
//   const matchesSearchQuery =
//     product.value.toLowerCase().includes('asset_utilization'.toLowerCase()) 
//     // product.category.toLowerCase().includes(searchQuery.toLowerCase());
//   return matchesSearchQuery;
//   falses(matchesSearchQuery)


// });


const changeValue = (e) => {
  // per()
  setBarData(getBarData(getChart, e));
};
  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["Black"],
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function (val) {
          return val
          // return  filteredProducts[0] ? '%' : '';
          // return Math.round(val) + (filteredProducts[0] ? '%' : '');

        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
          // return Math.round(val) + (filteredProducts ? '%' : '');
        },
      },
    },
  };

  
  useEffect(() => {
    setBarData(getBarData(getChart, keys[0]));
  }, [getChart]);


  return (
    <div>
      {/* <h3 className="text-center">Bank Name : {Label}</h3> */}
      <button
        onClick={() => {
          onButtonClick();
          onShow();
        }}
        className="btn btn-outline-primary float-right my-4"
      >
        Print <i className="fa fa-print" />
      </button>
      <br />
      <br />
      <div ref={ref} id="html-element">
        <div className="row">
          <div className="col-md-12">
            <div style={{ Width: "100%" }} className="mt-2 mb-5">
              <div className="chart-container  text-center">
                <h4 className="text-capitalize text-center my-4">
                  {keys
                    .filter(
                      (s) =>
                        s !== "bank_id" &&
                        s !== "bank" &&
                        s !== "id" &&
                        s !== "date" &&
                        s !== "branch" &&
                        s !== "establish_year" &&
                        s !== "year" &&
                        s !== "sum"
                    )
                    .map((e, index, array) => {
                      e = e.replace(/_/g, " ");

                      if (index === array.length - 1) {
                        return e;
                      } else if (index === array.length - 2) {
                        return e + " & ";
                      } else {
                        return e + ", ";
                      }
                    })}
                </h4>
                <select className="col-md-4 offset-md-8 text-capitalize " style={{height:'50px',border:'1px solid black',fontSize:'22px'}} onChange={(e) => changeValue(e.target.value)}>
                  {keys
                    .filter(
                      (s) =>
                        s !== "bank_id" &&
                        s !== "bank" &&
                        s !== "id" &&
                        s !== "date" &&
                        s !== "branch" &&
                        s !== "establish_year" &&
                        s !== "year" &&
                        s !== "sum"
                    )
                    .map((e, index, array) => (
                      <option value={e}>{e.replace(/_/g, " ")}</option>
                    ))}
                </select>

                <ReactApexChart
                  options={options}
                  series={barData}
                  type="bar"
                  height={400}
                />
                {/* <BarChart
                  width={window.innerWidth - 200}
                  height={window.innerHeight - 200}
                  layout="vertical" // Set the layout to "vertical"
                  data={getChart?.flat(1)}
                >
                  <CartesianGrid strokeDasharray="3 #" />
                  <XAxis type="number" />
                  <YAxis dataKey="year" type="category" />
                  <Tooltip
                    content={({ payload }) => {
                      if (payload && payload.length) {
                        const { bank_id, ...rest } = payload[0].payload;
                        const barColor = payload.map((e) => e.color); 
                        console.log(
                          payload,
                          barColor.map((e) => e),
                          "payload"
                        );
                        return (
                          <div className="custom-tooltip bg-white p-3">
                            <p>
                              {bank_id?.replace(/_/g, " ".toLocaleUpperCase)}
                            </p>{" "}
                            {Object.entries(rest).map(([key, value], index) => {
                              index = 0 + index;
                              if (key !== "year") {
                                return (
                                  <p style={{ color: barColor[index] }}>
                                    {key
                                      ?.replace(/_/g, " ")
                                      .toLocaleUpperCase()}
                                    : {value}
                                  </p>
                                );
                              }
                              return null;
                            })}
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Legend />
                  {keys
                    ?.filter(
                      (s) =>
                        s !== "bank_id" &&
                        s !== "bank" &&
                        s !== "id" &&
                        s !== "date" &&
                        s !== "branch" &&
                        s !== "establish_year" &&
                        s !== "year" &&
                        s !== "sum"
                    )
                    .map((key, index) => {
                      var r = Math.floor(Math.random() * 255);
                      var g = Math.floor(Math.random() * 255);
                      var b = Math.floor(Math.random() * 255);
                      console.log(key.replace(/_/g, " ").toLocaleUpperCase());
                      return (
                        <Bar
                          key={key}
                          dataKey={key}
                          name={key.replace(/_/g, " ").toUpperCase()}
                          fill={"rgb(" + r + "," + g + "," + b + ")"}
                        />
                      );
                    })}
                </BarChart> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <ModalBody>
          <PDFViewer style={{ width: `100%`, height: "100vh" }}>
            <Document>
              <Page size="A4">
                <div ref={pdfContainerRef}>
                  {convertedImg && (
                    <Image src={`${convertedImg}`} alt="no Img" />
                  )}
                  <br />
                  <br />
                  <br />
                </div>
              </Page>
            </Document>
          </PDFViewer>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHide}>
            Close
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default VerticalBarChart;
