import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../layouts/Blogsidebar";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../config/https";

const Content = () => {
  const [blogblock, setBlogblock] = useState([]);
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const getBlog = useCallback(() => {
    setLoader(true);
    axiosInstance
      .get(`blog/${id}`)
      .then((response) => {
        const Data = response.data.blog;
        console.log(Data);
        if (response.status === 200) {
          setBlogblock(Data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => setLoader(false));
  }, [id]);

  useEffect(() => {
    getBlog();
  }, [getBlog]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return formattedDate;
  };

  const formattedDate = formatDate(blogblock.date);
  return (
    <>
      {loader ? <div className="loader"> </div> : null}
      <section className="blog-section section-gap-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {/* Blog Details */}
              <div className="post-details-wrap">
                <div className="post-thumb">
                  <img src={blogblock.image} alt="" width={770} height={470} />
                </div>
                <div className="post-meta">
                  <ul>
                    <li>
                      <i className="far fa-user" />
                      <Link to="/blogdetail" className={"text-capitalize"}>
                        {blogblock.author_name}
                      </Link>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" />
                      <Link to="/blogdetail"> {formattedDate}</Link>
                    </li>
                  </ul>
                </div>
                <div className="post-content">
                  <h3 className="mb-3">{blogblock.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: blogblock.blog }} />
                  {/* <blockquote>
                  Smashing Podcast Episode With Paul Boag What Is Conversion
                  Optimization
                  <span className="author">Bailey Dobson</span>
                </blockquote>
                <h4 className="with-check">
                  <i className="far fa-check-circle" /> Inspired Design
                  Decisions With Otto Storch When Idea Copy
                </h4>
                <p>
                  No one rejects, dislikes, or avoids pleasure itself, because
                  it is pleasure, but because those who do not know how to
                  pursue pleasure rationally encounter consequences that are
                  extremely painful. Nor again is there anyone who loves or
                  pursues or desires to obtain pain of itself, because it is
                  pain, but because occasionally circumstances occur in which
                  toil and pain can procure him some great pleasure.
                </p> */}
                </div>
              </div>
              {/* Post Author */}
              {/* <div className="post-author-info">
              <div className="author-img">
                <img src={author} alt="" />
              </div>
              <div className="author-desc">
                <h4>Nathan George</h4>
                <p>
                  No one rejects, dislikes, or avoids pleasure itself, because
                  it is pleasure, but because those who do not know how to
                  pursue pleasure rationally encounter consequences that
                  aextremely painful. Nor again is there anyone who loves
                </p>
                <ul className="social-links">
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-behance" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-dribbble" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
              {/* Post Nav */}
              {/* <div className="post-nav row">
              <div className="prev-post col-md-6">
                <div className="img">
                  <img src={prevpost} alt="" />
                </div>
                <div className="content">
                  <span className="date">
                    <i className="far fa-calendar-alt" /> 25 Aug 2020
                  </span>
                  <h5>
                    <Link to="/blogdetail">
                      Smashing Podcast Epis <br /> With Rach Andrewe
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="next-post col-md-6">
                <div className="content">
                  <span className="date">
                    25 Aug 2020 <i className="far fa-calendar-alt" />
                  </span>
                  <h5>
                    <Link to="/blogdetail">
                      Implementing Infinite <br /> Loading In React
                    </Link>
                  </h5>
                </div>
                <div className="img">
                  <img src={nextpost} alt="" />
                </div>
              </div>
            </div> */}
              {/* Related Post */}
              {/* <div className="related-post">
              <h3 className="title">Related Post</h3>
              <div className="latest-post-loop row "> */}
              {/* {relatedpost.map((item, i) => (
                  <div key={i} className="col-lg-6 col-md-6 col-10 col-tiny-12">
                    <div className="latest-post-box-two">
                      <div className="post-thumb-wrap">
                        <div
                          className="post-thumb bg-img-c"
                          style={{ backgroundImage: "url(" + item.img + ")" }}
                        ></div>
                        <span className="post-date">
                          <i className="far fa-calendar-alt" />
                          {item.postdate}
                        </span>
                      </div>
                      <div className="post-desc">
                        <h3 className="title">
                          <Link to="/blogdetail">{item.title}</Link>
                        </h3>
                        <Link to="/blogdetail" className="post-link">
                          {item.urltext}{" "}
                          <i className="far fa-long-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))} */}
              {/* </div>
            </div> */}
              {/* Comments Template */}
              {/* <div className="comment-template">
                                <h3 className="title">Comments</h3> */}
              {/* Comments List */}
              {/* <ul className="comment-list">
                                    <li>
                                        <div className="author-img">
                                            <img src={comment1} alt="" />
                                        </div>
                                        <div className="comment-content">
                                            <h5 className="author-name">John F. Medina<span className="date">25 July 2020</span></h5>
                                            <p>
                                                But I must explain to you how all this mistaken idea of denouncing pleasure and
                                                praising pawas born and I will give you a complete account
                                            </p>
                                            <Link to="#" className="reply-btn">Reply <i className="far fa-long-arrow-alt-right" /></Link>
                                        </div>
                                        <ul className="children">
                                            <li>
                                                <div className="author-img">
                                                    <img src={comment2} alt="" />
                                                </div>
                                                <div className="comment-content">
                                                    <h5 className="author-name">Jeffrey T. Kelly<span className="date">25 July
                                                        2020</span>
                                                    </h5>
                                                    <p>
                                                        Again is there anyone who loves or pursues or desires to obtain paiits
                                                        ecause it is pain, but because occasionally circumstances occur in which
                                                    </p>
                                                    <Link to="#" className="reply-btn">Reply <i className="far fa-long-arrow-alt-right" /></Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div className="author-img">
                                            <img src={comment3} alt="" />
                                        </div>
                                        <div className="comment-content">
                                            <h5 className="author-name">Richard B. Zellmer<span className="date">25 July 2020</span>
                                            </h5>
                                            <p>
                                                Harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum
                                                soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
                                            </p>
                                            <Link to="#" className="reply-btn">Reply <i className="far fa-long-arrow-alt-right" /></Link>
                                        </div>
                                    </li>
                                </ul> */}
              {/* Comments form */}
              {/* <div className="comment-form">
                                    <h3 className="title">Leave A Reply</h3>
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group mb-30">
                                                    <input type="text" placeholder="Your Full Name" />
                                                    <span className="icon"><i className="far fa-user" /></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group mb-30">
                                                    <input type="email" placeholder="Your Email " />
                                                    <span className="icon"><i className="far fa-envelope" /></span>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="input-group textarea mb-30">
                                                    <textarea placeholder="Write Message" defaultValue={""} />
                                                    <span className="icon"><i className="far fa-pencil" /></span>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="main-btn">Send Reply</button>
                                            </div>
                                        </div>
                                    </form>
                                </div> */}
              {/* </div> */}
            </div>
            <div className="col-lg-4 col-md-8">
              {/* sidebar */}
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
