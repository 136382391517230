import React from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";

const Test = () => {
  const seriesData =  [
    {
    data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
  },
  {
    data: [200, 430, 678, 230, 440, 480, 490, 1300, 100, 1380]
  },
]

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
    },
  };

  React.useEffect(() => {
    ApexCharts.exec("chart", "updateOptions", options);
  }, [options]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={seriesData} type="bar" height={350} />
    </div>
  );
};

export default Test;
