import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Swal from "sweetalert2";
import { axiosInstance } from "../../config/https";
import Headertwo from "../layouts/Headertwo";
import VerticalBarChart from "./Barchart";
import Linechart from "./Linechart,";

export default function Datatable() {
  const options = [
    // Replace with your columns configuration
    { value: "id", label: "ID" },
    { value: "bank_id", label: "Bank Name" },
    { value: "date", label: "Year" },
    { value: "branch", label: "Branch Name" },
    { value: "establish_year", label: "Establish Year" },
    { value: "asset_growth_rate", label: "Asset Growth Rate" },
    { value: "asset_utilization", label: "Asset Utilization " },
    { value: "deposit_mill", label: "Deposit Mill " },
    {
      value: "deposit_growth_rate",
      label: "Deposit Growth Rate",
    },
    { value: "efficiency", label: "Efficiency" },
    {
      value: "equity_growth_rate",
      label: "Equity Growth Rate",
    },
    { value: "equity_in_mill", label: "Equity In Mill" },
    { value: "interest_exp_mill", label: "Interest Exp Mill" },
    {
      value: "interest_income_mill",
      label: "Interest Income Mill",
    },
    { value: "laon_growth_rate", label: "Loan growth rate" },
    { value: "loan_mill", label: "Loan Mill" },
    { value: "loan_to_deposit", label: "Laon to deposit $" },
    {
      value: "net_income_growth_rate",
      label: "Net income growth rate",
    },
    { value: "net_income_mill", label: "Net income mill" },
    {
      value: "net_interest_income_mill",
      label: "Net interest income mill",
    },
    {
      value: "net_interest_margin",
      label: "Net interest margin",
    },
    {
      value: "net_operating_revenue_mill",
      label: "Net operating revenue mill",
    },
    {
      value: "non_interest_exp_growth_rate",
      label: "Non interest exp growth rate",
    },
    {
      value: "non_interest_exp_mill",
      label: "Non interest exp mill",
    },
    {
      value: "non_performing_loan_mill",
      label: "Non performing loan mill",
    },
    { value: "overhead_ratio", label: "Overhead ratio" },
    {
      value: "profit_before_tax_mill",
      label: "Profit before tax mill",
    },
    { value: "profit_margin", label: "Profit margin" },
    { value: "roa", label: "ROA" },
    { value: "roe", label: "ROE" },
    { value: "totassets_mill", label: "Totassets mill" },
    { value: "yield", label: "Yield" },
    // Add more columns as needed
  ];
  const [newData, setNewData] = useState([options[0]]);
  const [chartdata, setChartdata] = useState(false);
  const animatedComponents = makeAnimated();
  const [startDate, setStartDate] = useState("");
  const [bank, setBank] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [bankid, setBankid] = useState("");
  const handleChange = (setState) => (e) => setState(e.target.value);
  const [show, setShow] = useState(false);
  const [resetButton, setResetButton] = useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [getChart, setgetChart] = useState([]);
  const [getlineChart, setgetlineChart] = useState([]);
  const getBank = () => {
    setLoader(true);
    axiosInstance
      .get("bank")
      .then((res) => {
        const Data = res?.data?.data;

        if (res.status === 200) {
          setLoader(false);
          setBank(Data);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => setLoader(false));
  };
  // console.log(columns);
  const [selectedOptionss, setSelectedOptionss] = useState(options.slice(0,3));
  // const handleSelect = (selectedOptions) => {
  //   setNewData(selectedOptions);
  // };
  const handleSelect = (selected) => {
    // Filter out the default options from the selected options
    const filteredOptions = selected.filter(
      (option) =>
        !options
          .slice(0, 3)
          .some((defaultOption) => defaultOption.value === option.value)
    );
    console.log(...filteredOptions,'filteredOptions')
    setSelectedOptionss([...options.slice(0, 3), ...filteredOptions]);
    setNewData(selectedOptionss);
  };
  console.log(selectedOptionss,'selectedOptionss')
  console.log(newData,'newData')
  const Chartdata = () => {
    setLoader(true);
    const formdata = new FormData();
    selectedOptionss
      .filter(
        (s) =>
          s.value !== "id" &&
          s.value !== "date" &&
          s.value !== "branch" &&
          s.value !== "bank_id" &&
          s.value !== "establish_year"
      )
      .map((e, i) => formdata.append(`col[${i}]`, e.value));
    bankid.map((e, i) => formdata.append(`bank_id[${i}]`, e.value));
    formdata.append("startDate", startDate);
    formdata.append("endDate", endDate);
    axiosInstance
      .post(`record/graph`, formdata)
      .then((res) => {
        const Data = res?.data?.data;
        setgetChart(Data);
        setLoader(false);
      })
      .catch((e) => {
        console.log(e);
        setLoader(false);
      });
  };
  const LineChart = () => {
    setLoader(true);
    const formdata = new FormData();
    selectedOptionss
      .filter(
        (s) =>
          s.value !== "id" &&
          s.value !== "date" &&
          s.value !== "branch" &&
          s.value !== "bank_id" &&
          s.value !== "establish_year"
      )
      .map((e, i) => formdata.append(`col[${i}]`, e.value));
    bankid.map((e, i) => formdata.append(`bank_id[${i}]`, e.value));
    formdata.append("startDate", startDate);
    formdata.append("endDate", endDate);
    axiosInstance
      .post(`bank/graph`, formdata)
      .then((res) => {
        const Data = res?.data?.data;
        setgetlineChart(Data);
        setLoader(false);
      })
      .catch((e) => {
        console.log(e);
        setLoader(false);
      });
  };

  const getFilter = () => {
    setLoader(true);
    if (bankid.length <= 0) {
      setLoader(false);
      Swal.fire({
        icon: "warning",
        title: "Select Bank First",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    } else if (selectedOptionss.some((s) => s.value !== "id") === false) {
      setLoader(false);
      Swal.fire({
        icon: "warning",
        title: "Select Data with Id and any fields",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    } else {
      axiosInstance
        .get(
          `record?startDate=${startDate}&endDate=${endDate}${selectedOptionss
            ?.map((e, i) => `&col[${i}]=${e.value}`)
            .join("")}${bankid
            ?.map((e, i) => `&bank_id[${i}]=${e.value}`)
            .join("")}`
        )
        .then((res) => {
          setLoader(false);
          const Data = res?.data?.data;
          // console.log(res, Data);
          switch (true) {
            case res.data.status === true || res.status === 200:
              if (
                selectedOptionss.some(
                  (s) =>
                    s.value !== "id" &&
                    s.value !== "date" &&
                    s.value !== "branch" &&
                    s.value !== "bank_id" &&
                    s.value !== "establish_year"
                )
              ) {
                Chartdata();
                LineChart();
                setChartdata(false);
              }

              Swal.fire({
                icon: "success",
                title: res?.data?.message,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              setChartdata(true);
              setRow(Data);
              setLoader(false);
              setShow(false);
              setResetButton(true);
              break;
            default:
              setLoader(false);
          }
        })
        .catch((e) => {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: "No Internet Connection",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        });
      setLoader(false);
    }
  };
  const [newrows, setRow] = useState([]);

  useEffect(() => {
    getBank();
    setShow(true);
  }, []);
  const [filteredRows, setFilteredRows] = useState(newrows);
  console.log(filteredRows, "filteredRows");
  newrows.forEach((obj) => {
    // Add the percentage sign to interest_income_mill property
    obj.asset_growth_rate = `${parseFloat(obj.asset_growth_rate).toFixed(
      2
    )}%`.replace(/%+/g, "%");
    obj.asset_utilization = `${parseFloat(obj.asset_utilization).toFixed(
      2
    )}%`.replace(/%+/g, "%");
    obj.deposit_growth_rate = `${parseFloat(obj.deposit_growth_rate).toFixed(
      2
    )}%`.replace(/%+/g, "%");
    obj.efficiency = `${parseFloat(obj.efficiency).toFixed(2)}%`.replace(
      /%+/g,
      "%"
    );
    obj.equity_growth_rate = `${parseFloat(obj.equity_growth_rate).toFixed(
      2
    )}%`.replace(/%+/g, "%");
    obj.net_income_growth_rate = `${parseFloat(
      obj.net_income_growth_rate
    ).toFixed(2)}%`.replace(/%+/g, "%");
    obj.laon_growth_rate = `${parseFloat(obj.laon_growth_rate).toFixed(
      2
    )}%`.replace(/%+/g, "%");
    obj.loan_to_deposit = `${parseFloat(obj.loan_to_deposit).toFixed(
      2
    )}%`.replace(/%+/g, "%");
    obj.net_interest_margin = `${parseFloat(obj.net_interest_margin).toFixed(
      2
    )}%`.replace(/%+/g, "%");
    obj.non_interest_exp_growth_rate = `${parseFloat(
      obj.non_interest_exp_growth_rate
    ).toFixed(2)}%`.replace(/%+/g, "%");
    obj.overhead_ratio = `${parseFloat(obj.overhead_ratio).toFixed(
      2
    )}%`.replace(/%+/g, "%");
    obj.profit_margin = `${parseFloat(obj.profit_margin).toFixed(2)}%`.replace(
      /%+/g,
      "%"
    );
    obj.roa = `${parseFloat(obj.roa).toFixed(2)}%`.replace(/%+/g, "%");
    obj.roe = `${parseFloat(obj.roe).toFixed(2)}%`.replace(/%+/g, "%");
  });
  console.log(newrows, "newrows");

  // console.log(options);
  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    setSearch(keyword);
    const filteredData = newrows.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === "string" && value.toLowerCase().includes(keyword)
      )
    );

    setFilteredRows(filteredData);
  };

  const renderCell = (params) => {
    const cellValue = params.value;
    const keyword = search.toLowerCase();

    if (
      cellValue &&
      typeof cellValue === "string" &&
      keyword &&
      cellValue.toLowerCase().includes(keyword)
    ) {
      const startIndex = cellValue.toLowerCase().indexOf(keyword);
      const endIndex = startIndex + keyword.length;

      const highlightedValue = (
        <>
          {cellValue.slice(0, startIndex)}
          <span className="bg-primary text-white ">
            {cellValue.slice(startIndex, endIndex)}
          </span>
          {cellValue.slice(endIndex)}
        </>
      );

      return <div>{highlightedValue}</div>;
    }

    return <div>{cellValue}</div>;
  };
  const columns = selectedOptionss.map((option, index) => ({
    field: option.value,
    headerName: option.label,
    width: index + 200,
  }));


  const currentYear = new Date().getFullYear(); // Get the current year
  const yearArray = [];
  for (let year = 2018; year <= currentYear; year++) {
    yearArray.push(year.toString());
  }
  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <Headertwo className="bg-dark" />
      <div className="container-fluid my-2" style={{ maxWidth: "95%" }}>
        <br />
        <br />
        <br />
        <br />
        <div className="card shadow-lg col-md-12">
          <div
            className="mb-3"
            style={{
              width: "100%",
              height: newrows.length !== 0 ? "auto" : `auto`,
            }}
          >
            <div className=" mx-1">
              <h2 className="text-center mt-2">
                Financial Data Dashboard{" "}
                <i
                  className="fa fa-tv bounce"
                  style={{ color: "#ff4a17", faAnimationuration: 0.1 }}
                />
              </h2>{" "}
              <input
                type="search"
                placeholder="Search"
                className="form-control w-25 float-left "
                onChange={handleSearch}
              />
              {resetButton ? (
                <>
                  <div
                    className="btn btn-outline-danger float-right"
                    onClick={() => {
                      setShow(true);
                      setRow([]);
                      setSelectedOptionss(newData)
                    }}
                  >
                    Reset filter <i className="fa fa-repeat" />
                  </div>
                </>
              ) : (
                <button
                  className="btn btn-primary float-right"
                  onClick={onShow}
                >
                  Filteration <i className="fa fa-filter" />
                </button>
              )}
            </div>
          </div>
          {newrows.length !== 0 ? (
            <>
              {chartdata ? (
                <>
                  <VerticalBarChart getChart={getChart} loader={loader} selectedOptionss={selectedOptionss} />
                  <hr />
                  <Linechart getChart={getlineChart} loader={loader}  />

                  <h6 className="text-center">
                    CopyRight &copy; 2023, All Right Reseverd to EthioFinance
                  </h6>
                </>
              ) : null}
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: newrows.length !== 0 ? "auto" : 400,
                }}
              >
                <DataGrid
                  // rows={newrows}
                  rows={search === "" ? newrows : filteredRows}
                  columns={columns.map((column) => ({
                    ...column,
                    renderCell,
                  }))}
                  pagination
                  pageSize={5}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <ModalHeader>Get Data</ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="">Bank Name</label>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  onChange={setBankid}
                  options={bank}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor=""> Financial Metrices</label>
                <Select
                  // components={animatedComponents}
                  // isMulti
                  // isClearable={false}
                  // defaultValue={options.slice(0,3)}
                  // onChange={handleSelect}
                  // options={options}
                  components={animatedComponents}
                  isMulti
                  isClearable={false}
                  value={selectedOptionss}
                  onChange={handleSelect}
                  options={options}
                />
                <p
                  className=" fs-6 my-2 text-primary"
                  style={{ lineHeight: 1.4, fontSize: 12 }}
                >
                  <i className="fa fa-info-circle" /> Choose the Financial
                  Metrics from the drop down menu and chose the range of years
                  for the data. If you want to see only one year data, make the
                  From and To years the same. Example, for 2022 financial data,
                  the start and end years will be 2022
                </p>
              </div>
              <div className="col-md-6">
                <label htmlFor="">From (Fiscal Year)</label>
                <select
                  className="form-control mb-3"
                  onChange={handleChange(setStartDate)}
                >
                  <option selected disabled hidden>
                    Select Start Date...
                  </option>
                  {yearArray.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="">To (Fiscal Year)</label>
                <select
                  className="form-control mb-3"
                  onChange={handleChange(setEndDate)}
                >
                  <option selected disabled hidden>
                    Select End Date...
                  </option>
                  {yearArray.map((e) => (
                    <option value={e}>{e}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHide}>
            Close
          </div>

          <div className="btn btn-outline-success" onClick={getFilter}>
            Sumbit
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
