import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../img/Logo.png";
import Navmenu from "./Navmenu";

const Header = () => {
  const [matches] = useState(false);
  const [stickyheader] = useState(false);
  const [toggleMethod, setToggleMethod] = useState(false);
  useEffect(() => {
    // Add necessary logic for setting the state of `matches` and `stickyheader`
    // You can use window.matchMedia and scroll event listeners to update the state values
    // Example: window.matchMedia("(max-width: 768px)").matches
    // Example: window.addEventListener('scroll', handleScroll);
  }, []);

  const toggleClass = () => {
    setToggleMethod((prevState) => !prevState);
  };

  const mobileActive = matches ? "breakpoint-on" : "";
  const smallMobileActive = matches ? "d-none" : "";
 
  return (
    <header className={`sticky-header ${stickyheader ? "sticky-on" : ""}`}>
      {/* Header Menu */}
      <div className="header-nav">
        <div className="container-fluid container-1600">
          <div className={`nav-container ${mobileActive}`}>
            {/* Site Logo */}
            <div className="site-logo">
              <Link to="/">
                <img src={Logo} alt="logo" width={300} />
              </Link>
            </div>
            {/* Main Menu */}
            <div
              className={
                toggleMethod
                  ? "nav-menu d-lg-flex align-items-center menu-on"
                  : "nav-menu d-lg-flex align-items-center"
              }
            >
              {/* Navbar Close Icon */}
              <div className="navbar-close" onClick={toggleClass}>
                <div className="cross-wrap">
                  <span />
                  <span />
                </div>
              </div>
              {/* Menu Items */}
              <div className="menu-items">
                <Navmenu />
              </div>
              {/* Pushed Item */}
              <div className="nav-pushed-item" />
            </div>
            {/* Navbar Extra */}
            <div className="navbar-extra d-lg-block d-flex align-items-center">
              {/* Navbtn */}
              <div className={`navbar-btn nav-push-item ${smallMobileActive}`}>
                <Link to="/contact" className="main-btn main-btn-3 my-3">
                  <i className="fa fa-inbox" /> Contact Us
                </Link>

                <Link to="/Datatable" className="main-btn main-btn-3 my-3 mx-1">
                  <i className="fa fa-tv bounce" /> Dashboard
                </Link>
              </div>
              {/* Navbar Toggler */}
              <div
                className={
                  toggleMethod ? "navbar-toggler active" : "navbar-toggler"
                }
                onClick={toggleClass}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
