import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosInstance } from "../../config/https";
import Logo from "../../img/Logo.png";
const SignUp = () => {
  const navigate = useHistory();
  const [showPassword,] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState(false);
  const [password, setPassword] = useState("");
  const [con_password, setCon_Password] = useState("");
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const isSignup = () => {
    setErrors(false);
    if (
      fname !== "" &&
      lname !== "" &&
      email !== "" &&
      password !== "" &&
      con_password !== "" &&
      password === con_password

    ) {
      setLoader(true)
      const formdata = new FormData();
      formdata.append("name", fname + "" + lname);
      formdata.append("email", email);
      formdata.append("password", password);
      axiosInstance
        .post("signup", formdata)
        .then((e) => {
          const Data = e.data;
          console.log(Data);
          if (Data?.status === true) {
            setLoader(false)
            Swal.fire({
              icon: "success",
              title: Data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            setLoader(false)
            navigate("/login");
          } else {
            setLoader(false)
            Swal.fire({
              icon: "error",
              title: Data?.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
          }
        })
        .catch(((e) => {
          setLoader(false)

          console.log(e)
        }));
    } else {
      setErrors(true);
      setLoader(false)

      Swal.fire({
        icon: "error",
        title: "Validation Error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    }
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <div style={{ backgroundColor: 'rgb(218 69 27)', height: "100vh" }}>

        <div className="container ">
          <div className="row">
            <div className="col-md-12 ">
              <div className="row">
                <div className="col-md-3" />
                <div className="col-md-6">
                  <br />
                  <br />
                  <div
                    className="card shadow-lg  rounded  p-3"
                    style={{ backgroundColor: "#3c383830" }}
                  >
                    <div className="container">
                      <div className="text-center">
                        <img src={Logo} alt="" className="w-75 rounded my-3" />
                      </div>
                      <div className="my-3">
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label>
                              <h6 className="text-white">First Nmae</h6>
                            </label>
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                className={`form-control ${errors && fname === ""
                                  ? "border-danger border-2 rounded"
                                  : ""
                                  }`}
                                placeholder="John"
                                onChange={(e) => setFname(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <label>
                              <h6 className="text-white">Last Name</h6>
                            </label>
                            <div className="input-group mb-2">
                              <input
                                type="text"
                                className={`form-control ${errors && lname === ""
                                  ? "border-danger border-2 rounded"
                                  : ""
                                  }`}
                                placeholder="Doe"
                                onChange={(e) => setLname(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-12">
                            <label>
                              <h6 className="text-white">Email</h6>
                            </label>
                            <div className="input-group mb-2">
                              <input
                                type="email"
                                className={`form-control ${errors && email === ""
                                  ? "border-danger border-2 rounded"
                                  : ""
                                  }`}
                                placeholder="abc@gmail.com"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <label>
                              <h6 className="text-white">Password</h6>
                            </label>
                            <div className="input-group mb-2">
                              <input
                                type={showPassword ? "text" : "password"}
                                className={`form-control ${(errors && password === "") ||
                                  password !== con_password
                                  ? "border-danger border-2 rounded"
                                  : ""
                                  }`}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <label>
                              <h6 className="text-white">
                                Confirmation Password
                              </h6>
                            </label>
                            <div className="input-group mb-2">
                              <input
                                type={showPassword ? "text" : "password"}
                                className={`form-control ${(errors && con_password === "") ||
                                  password !== con_password
                                  ? "border-danger border-2 rounded"
                                  : ""
                                  }`}
                                onChange={(e) => setCon_Password(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="text-center col-md-12 mt-1">
                            <p className="text-white ">
                              You have account?{" "}
                              <Link to={"/login"} className="text-primary">
                                Login
                              </Link>
                            </p>

                            <button
                              className="main-btn main-btn-4 w-50 "
                              onClick={isSignup}
                            >
                              Signup
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
